import Vue from 'vue/dist/vue.esm'
import Axios from 'axios/dist/axios'
import VueScrollTo from 'vue-scrollto/vue-scrollto'

Vue.use(VeeValidate)
Vue.use(VueScrollTo)
Vue.prototype.$http = Axios

import LocationSearch from '../shared/location_search.vue'
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import tippy from 'tippy.js'

new Vue({
  el: '#guided-search-form',
  data: {
    disease: "",
    currentSlide: 1,
    questionCount: null,
    latestSlideViewed: 1
  },
  components: {
    VueSimpleSuggest,
    LocationSearch
  },
  mounted() {
    // don't submit form on enter
    $("#guided-search-form").on("keypress", (e) => {
      return e.keyCode != 13
    })
    // count the number of questions
    this.questionCount = $(this.$el).find('.question').length

    tippy('[data-tippy-content]', {
      arrow: true,
      theme: 'light'
    })
  },
  methods: {
    diseasePrepop(q) {
      let newTerms = []
      let requestParams = {
        method: 'get',
        url: `/filtered_search/prepop?q=${q}`
      }

      let promise = this.$http(requestParams).then((response) => {
        return response.data.terms
      }).catch((error) => {
        // console.log(error)
      })

      return promise
    },    
    validateBeforeSubmit() {
      this.$validator.validateAll().then(valid => {

        if (!valid) {
          this.goToQuestion(this.firstSlideWithError())
          return;
        }
        
        // console.log(JSON.stringify(this.$children))
        let childPromises = this.$children.map((vm) => {
          return vm.$validator.validateAll()
        })

        Promise.all(childPromises).then(childValidations => {
          if (!childValidations[0]) {
            this.goToQuestion(this.firstSlideWithError())
            return;
          }
          
          $("#guided-search-form").submit()
        }).catch((e) => {
        })

      }).catch((e) => {
      })
    },

    goToQuestion(num) {
      this.currentSlide = num
      var newLeft = ((num - 1 )* 100) * -1 + '%'
      $(this.$el).css('left',newLeft)
    },
    
    nextQuestion() {
      this.currentSlide += 1
      this.goToQuestion(this.currentSlide)
    },
   
    backQuestion() {
      this.currentSlide -= 1
      this.goToQuestion(this.currentSlide)
    },

    firstSlideWithError() {
      let el = $($(".danger")[0])
      // special case: catch when the autocomplete location field is empty, since we are using somewhat of a hack for its validation
      if (el.length == 0) {
        return 4
      }
      
      let q = el.parents('.question')
      return $('.question').index(q) + 1
    }

  }
  
})
