<template>
  <div :class="{ mini: mini, full: !mini }">
    <div v-if="!mini" class="guided-text">
      <h3 class="is-mb0 light is-text-center">Searching for trials in the United States</h3>
    </div>
    <div class="checkbox-wrap">
      <input class="is-hide" type="checkbox" id="location-limit-toggle"  v-model="hasLocations" />
      <label v-if="mini" class="is-faux-checkbox is-heading is-mb0" for="location-limit-toggle">Limit by location</label>
      <label v-else class="is-faux-checkbox is-heading is-mb0 checkbox-text" style="color: #fff !important; font-weight: 300; margin: auto;" for="location-limit-toggle">Limit search to a specific location</label>
    </div>

    <div class="input-group-wrap" v-if="hasLocations" v-for="(location, key, index) in locations">
      <div class="input-group is-mt1">
        <div class="is-quarter is-float-left within-wrap">
          <label class="is-mt1" :for="'locale-' + key">Within</label>
        </div>
        <div class="is-quarter is-float-left radius-input">
          <input class="is-mb1" type="text" name="location[][radius]" :class="{'radius': true, 'danger': errors.has('radius-' + key)}" v-model="location.radius" :key="'radius-' + key" v-validate="'required|numeric'" :data-vv-name="'radius-' + key" data-vv-as="radius">
          <div style="font-size: 1rem" class="error" v-show="errors.has('radius-' + key)">{{ errors.first('radius-' + key) }}</div>
        </div>
        <div class="is-quarter is-float-left is-text-center">
          <label class="is-mt1" :for="'locale-' + key">miles of</label>
        </div>
        <div class="is-quarter is-float-left autocomplete-wrap" v-if="!mini">
          <vue-google-autocomplete
            :id="'locale-' + key"
            classname="locale-autocomplete"
            placeholder="City, State, or Address"
            types="geocode"
            country="us"
            v-on:placechanged="getAddressData"
            v-on:inputChange="inputChange(key)"
          >
          </vue-google-autocomplete>
          <div style="font-size: 1rem" class="error autocomplete-error" v-if="errors.has('locale-' + key)">Please select a location</div>
        </div>
        <div style="clear: both;"></div>

        <vue-google-autocomplete
          v-if="mini"
          :id="'locale-' + key"
          classname="locale-autocomplete"
          placeholder="City, State, or Address"
          types="geocode"
          country="us"
          v-on:placechanged="getAddressData"
          v-on:inputChange="inputChange(key)"
        >
        </vue-google-autocomplete>

        <div style="font-size: 1rem" class="error autocomplete-error" v-if="mini && errors.has('locale-' + key)">Please select a location</div>

        <input type="hidden" v-model="location.locale" name="location[][locale]" v-validate="'required'" :key="'locale-' + key" :data-vv-name="'locale-' + key" data-vv-as="locale">
        <input type="hidden" v-model="location.lat" name="location[][lat]">
        <input type="hidden" v-model="location.lng" name="location[][lng]">

        <a class="remove-link icon-link is-x is-circled active" v-if="showRemove" href="#" v-on:click.prevent="remove(key)">Remove</a>
      </div>

    </div>

    <div style="clear: both;"></div>
    <a class="add-location button is-outline is-sm is-mt1" v-if="mini && hasLocations" href="#" v-on:click.prevent="add">Add Location</a>
  </div>
</template>

<script>
  import VueGoogleAutocomplete from 'vue-google-autocomplete'

  export default {
    components: {
      VueGoogleAutocomplete
    },
    data() {
      return {
        hasLocations: this.initialHasLocations,
        locations: this.initialLocations
      }
    },
    props: {
      mini: { // rendered inside search filter
        default: false
      },
      initialLocations: {
        default: () => {
          return [{radius: '50', locale: '', lat: '', lng: ''}]
        }
      },
      initialHasLocations: {
        default: false
      }
    },
    computed: {
      showRemove() {
        return (this.locations.length > 1)
      }
    },
    mounted() {
      this.initializeAutocompleteInput()
    },
    methods: {
      initializeAutocompleteInput() {
        this.locations.forEach((el, i, arr) => {
          if (el.locale.length > 0) {
            document.getElementById("locale-" + i).value = el.locale
          }
        })
      },

      add() {
        this.locations.push({radius: '50', locale: '', lat: '', lng: ''})
      },

      remove(key) {
        this.locations.splice(key, 1)
        this.initializeAutocompleteInput()
      },

      reset() {
        this.locations = [{radius: '50', locale: '', lat: '', lng: ''}]
        this.hasLocations = false
      },

      setLocations(locs) {
        this.locations = locs
        this.hasLocations = true
      },

      getAddressData(addressData, placeResultData, elId) {
        var locationKey = parseInt(elId.split('-')[1])
        var loc = this.locations[locationKey]
        loc.lat = addressData.latitude
        loc.lng = addressData.longitude
        loc.locale = document.getElementById(elId).value
      },

      inputChange(key) {
        var loc = this.locations[key]
        if (document.getElementById('locale-' + key).value.length == 0) {
          loc.lat = ''
          loc.lng = ''
          loc.locale = ''
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .full {
    padding-top: 50px;
  }

  .full .checkbox-wrap {
    text-align: center;
  }

  .full label {
    color: #fff;
    font-weight: 300;
  }

  .full input[type="text"] {
    background: rgba(255, 255, 255, 0.3) !important;
    border-radius: 24px;
    border: 1px solid #fff;
    color: #fff !important;
    /* padding: 2rem; */

    &::placeholder {
      color: #ececec;
      opacity: 1;
    }
  }

  .full .input-group .is-quarter {
    margin-left: -16px;
  }

  .guided-text {
    position: absolute;
    top: 24px;
    width: 100%;
  }

  .checkbox-text {
    font-size: 1.9rem !important;
    color: #6C6A67 !important;
    padding-left: 32px;
  }

  .error {
    color: red;
    font-family: "DM Sans", helvetica, arial;
    margin-top: -8px;
  }

  .full .error {
    font-size: 1.2rem !important;
    color: #9e0000;
  }

  .full .remove-link {
    margin-left: 10px;
  }

  .add-location {
    margin-left: 22px;
    font-weight: bold;
  }

  .locale-autocomplete {
    margin-bottom: 10px;
  }

  .autocomplete-error {
  }

  .mini .input-group {
    // margin-bottom: 34px;
    margin-left: 22px;
  }

  .mini .radius-input {
    margin-left: -16px;
  }

  .full .within-wrap {
    text-align: center;
  }

  .full .radius-input {
    width: 50px;
  }

  .full .autocomplete-wrap {
    width: 240px;
  }

  .input-group-wrap {
    max-width: 475px;
    margin: auto;
  }
</style>
